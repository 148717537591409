<template>
  <div>
    <a-modal :visible.sync="show"
             width="1000px"
             title="退款详情"
             :footer="null"
             @cancel="$emit('update:show', false)">
      <div class="footer" style="margin-top:0;">
        <a-form-model :model="form"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">

          <!-- 顾客提交的银行信息 -->
          <a-form-model-item prop label="顾客收款信息"
            v-if="infoData.refund_account_name || infoData.refund_bank || infoData.refund_card_number || infoData.refund_phone">
            <div>
              <div v-if="infoData.refund_account_name">户名：{{infoData.refund_account_name}}</div>
              <div v-if="infoData.refund_bank">银行：{{infoData.refund_bank}}</div>
              <div v-if="infoData.refund_card_number">卡号：{{infoData.refund_card_number}}</div>
              <div v-if="infoData.refund_phone">手机号：{{infoData.refund_phone}}</div>
            </div>
          </a-form-model-item>

          <!-- 退款金额 -->
          <a-form-model-item prop label="退款金额">
            <span class="font-bold">{{infoData.return_amount}}元</span>
            <span class="grey">（含赠送的总金额为：{{infoData.apply_amount}}元）</span>
          </a-form-model-item>

          <!-- 客服 备注 -->
          <a-form-model-item prop label="审核备注">
            <span>{{infoData.audit_remarks}}</span>
          </a-form-model-item>

          <!-- 财务 备注 -->
          <a-form-model-item prop label="财务备注">
            <span>{{infoData.return_remarks}}</span>
          </a-form-model-item>
          <!-- 财务 退款凭证 -->
          <a-form-model-item prop label="退款凭证"
            v-if="infoData.payment_voucher_image_url">
            <img class="img-class" :src="infoData.payment_voucher_image_url"
                 @click="handlerBigImg(infoData.payment_voucher_image_url)" />
          </a-form-model-item>
        </a-form-model>
        
        <a-divider />
        <!-- 关联的充值 -->
        <div>
          <h3 class="mt-3 title-bg">涉及的充值记录</h3>
          <base-table rowKey="recharge_id"
            :columnsData="rechargeLogColumns"
            :tableData="rechargeList">
            <template #recharge_order_no="{record}">
              <a-button type="link" class="p-0" 
                @click="handlerRechargeDetail(record)">{{record.recharge_order_no}}</a-button>
            </template>
            <template #invoice_apply_id="{record}">
              <a-button v-if="record.invoice_apply_id" type="link" class="p-0" @click="handlerInvoiceDetail(record.invoice_apply_id)">￥{{record.invoice_open_amount}}</a-button>
              <template v-else>未开票</template>
            </template>
          </base-table>
        </div>
      </div>
    </a-modal>

    <a-modal class="preview-class"
             title="退款图片"
             width="50%"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>

    <!-- 发票详情 -->
    <InvoiceDetail
      v-if="isShowInvoiceDetail"
      :show.sync="isShowInvoiceDetail"
      :requestID="transformDetailID"
    />

    <!-- 钱包充值详情 -->
    <rechargeDetail v-if="isShowRechargeDetail" :show.sync="isShowRechargeDetail"
        :rechargeInfo="activeRechargeInfo"></rechargeDetail>
  </div>
</template>


<script>
import { getWalletRefundDetail } from '@/api/trading-manage/refund.js'
// import { formatGoodsType } from '@/utils/type.js'
import { getVipRechargeDetail } from '@/api/vip.js'
import { rechargeLogColumns } from '../columns'
import InvoiceDetail from "../../../invoice/topup-invoice/components/detail"
import rechargeDetail from "../../../member/components/rechargeDetail.vue"


export default {
  components: { InvoiceDetail, rechargeDetail },
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
      rechargeLogColumns,

      form: {},
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      previewVisible: false,
      previewImage: '',

      img2List: [],
      infoData: {},

      rechargeList: [ ],
      transformDetailID: '',
      isShowInvoiceDetail: false,

      activeRechargeInfo: null,
      isShowRechargeDetail: false,
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      getWalletRefundDetail({ id: this.id }).then(res => {
        if (res.code === 0) {
          this.infoData = res.data
          this.img2List = res.data.payment_voucher_image_url

          const recharge_ids = res.data.recharge_ids.split(",")
          recharge_ids.map(id=>{
            this.getRechargeDetail(id)
          })
        }
      })
    },

    getRechargeDetail(recharge_id){
      getVipRechargeDetail({
        recharge_id: recharge_id
      }).then(res=>{
        if(res.code == 0){
          this.rechargeList.push({
            ...res.data,
            recharge_id: recharge_id
          })
        }
      })
    },

    handlerInvoiceDetail(id){
      this.transformDetailID = id
      this.isShowInvoiceDetail = true
    },
    handlerRechargeDetail(recharge_info){
      this.activeRechargeInfo = recharge_info
      this.isShowRechargeDetail = true
    },

    handlerBigImg (e) {
      this.previewImage = e
      this.previewVisible = true
    },

    handlerCancel () {
      this.isShow = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .grey{
    color: #999;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>